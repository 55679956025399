import { Injectable, signal, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CMSLayoutService {
  readonly sideNavTemplate = signal<TemplateRef<any> | undefined>(undefined);
  readonly pageTitle = signal<string>(null);
  constructor() {}

  setTitle(title?:string){
    this.pageTitle.set(title)
  }
}

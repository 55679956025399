import {
  Component,
  computed,
  inject,
  input,
  linkedSignal,
  model,
  output,
  resource,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { SharedModule } from '../../../../../Shared/shared.module';
import { CMSService } from '../../cms.service';
import {
  IAddCMSContentEvent,
  ICMSContent,
  ICMSReference,
  ICMSResource,
  ICMSResourceQuery,
} from '../../cms.model';
import { debounceTime, filter, lastValueFrom, Subject } from 'rxjs';
import { SaveCMSResourceService } from './save-cms-resource.service';
import SaveCmsContentComponent from '../../cms-content-bank/save-cms-content/save-cms-content.component';
import { PageTemplateComponent } from '../../../../../Shared/components/page-template/page-template.component';
import { CMSResourceComponent } from '../cms-resource/cms-resource.component';
import { ModalComponent } from '../../../../../Shared/components/modal/modal.component';
import { CMSLayoutService } from '../../cms-layout/cms-layout.service';
import { CMSResourceService } from '../cms-resource/cms-resource.service';
import { CmsContentBankComponent } from '../../cms-content-bank/cms-content-bank.component';
import { SaveContentReferenceComponent } from '../../cms-references/save-content-reference/save-content-reference.component';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'save-cms-resource',
  standalone: true,
  imports: [
    SharedModule,
    SaveCmsContentComponent,
    CMSResourceComponent,
    CmsContentBankComponent,
    SaveContentReferenceComponent,
  ],
  providers: [CMSResourceService, SaveCMSResourceService],
  templateUrl: './save-cms-resource.component.html',
  styleUrl: './save-cms-resource.component.scss',
})
export default class SaveCMSResourceComponent extends PageTemplateComponent {
  readonly service = inject(CMSService);
  readonly cmsResourceService = inject(CMSResourceService);
  readonly saveService = inject(SaveCMSResourceService);
  readonly cmsLayoutService = inject(CMSLayoutService);

  readonly hideReferencing = input<boolean>();
  readonly isComponent = input<boolean>();
  readonly allowSubRemapping = input<boolean>();
  readonly toolbarTemplate = input<TemplateRef<any>>();
  readonly reference = input<Omit<ICMSReference, 'id'>>();
  readonly referenceItemLabel = input<string>();
  readonly resourceSuffix = input<ICMSResource[]>();
  readonly applicationCode = input<string>();
  readonly baseReference = input<ICMSReference>();
  readonly overrideQuery = input<ICMSResourceQuery>();
  readonly saved = output();
  readonly selectedContentBlockList = output<ICMSContent[]>();

  // readonly selectedForm = signal(this.saveService.parentForm);
  readonly stagedAddEvent = signal<IAddCMSContentEvent>(null);
  readonly selectedReference = signal<ICMSReference>(null);
  protected readonly autoSave = model<boolean>(true);
  readonly autoSaving = signal(false);
  readonly autoSave$ = new Subject<boolean>();

  readonly editContentModalRef = viewChild<ModalComponent>('editContentModal');
  readonly saveContentModalRef = viewChild<ModalComponent>('saveContentModal');
  readonly selectContentModalRef = viewChild<ModalComponent>('selectContentModal');

  readonly content = computed<ICMSResource>(() => {
    // if (!this._content()) return null;
    // debugger;
    const { subHelpGuideContent, ...content } = this._content() || {};
    return <ICMSResource>{
      ...content,
      subHelpGuideContent: subHelpGuideContent?.map((x) => ({ ...x, _hidePreview: true })),
    };
  });
  readonly selectedContentBlocks = computed(() =>
    this.allowSubRemapping() ? this.content()?.subHelpGuideContent?.filter((x) => x._selected) || [] : [],
  );
  readonly selectedContent = signal<ICMSContent>(null);
  protected readonly query = linkedSignal<ICMSResourceQuery>(() => {
    if (this.isComponent() || this.overrideQuery()) return this.overrideQuery();
    else {
      const routeQuery = this.routeQuery();
      if (this.isCreate())
        if ('id' in routeQuery) return { helpGuideContentId: routeQuery.id };
        else return null;
      else return routeQuery as ICMSResourceQuery;
    }
  });

  private readonly referencesRefresher = signal(null);
  readonly referenceQuery = computed<ICMSReference>(() => ({ helpGuideContentId: this.content()?.id }));
  readonly saveContentBaseData = computed(() => ({
    applicationCode: this.applicationCode() || this.content()?.applicationCode,
  }));
  readonly referencesResource = resource({
    request: () => ({ query: this.referenceQuery(), referencesRefresher: this.referencesRefresher() }),
    loader: async ({ request }) => {
      try {
        const { query } = request;
        if (query.helpGuideContentId) {
          return lastValueFrom(
            this.service.searchContentReference({ helpGuideContentId: query.helpGuideContentId }),
          ).then((r) => r.content);
        }
      } catch (error) {
        this.uS.info(error, 0);
      }
      return null;
    },
  });
  readonly references = linkedSignal<ICMSReference[]>(() => this.referencesResource.value());
  readonly loadingReferences = computed<boolean>(() => this.referencesResource.isLoading());
  protected computedHeader = computed<string>(() => `Create resource for ${this.referenceItemLabel() || ''}`);

  readonly sub = this.uS.createSubscriptionManager();

  readonly routeQuery = toSignal<ICMSResourceQuery | { id: string }>(this.route.queryParams);
  readonly contentResource = resource({
    request: () => ({
      overrideQuery: this.overrideQuery(),
      routeQuery: this.routeQuery(),
      isCreate: this.isCreate(),
      isEdit: this.isEdit(),
      isComponent: this.isComponent(),
    }),
    loader: async ({ request }) => {
      const { overrideQuery, routeQuery, isCreate, isEdit, isComponent } = request;
      try {
        if (!isComponent && isCreate && 'id' in routeQuery) {
          const id = routeQuery.id;
          if (id) {
            let content = await lastValueFrom(this.service.getContentResource({ helpGuideContentId: id }));
            if (!content) content = await lastValueFrom(this.service.getContentById(id));
            this.cmsLayoutService.setTitle(`Create Help Resource - ${content.title}`);
            return content;
          }
        } else if (isEdit || !!overrideQuery) {
          const content = await lastValueFrom(
            this.service.getContentResource(overrideQuery || (routeQuery as ICMSResourceQuery)),
          );
          this.cmsLayoutService.setTitle(`Edit Help Resource - ${content.title}`);
          return content;
        } else {
          return null;
        }
      } catch (error) {
        this.service.uS.info(error, 0);
        return null;
      }
      return null;
    },
  });
  private readonly _content = linkedSignal<ICMSResource>(() => this.contentResource.value());
  protected computedLoading = computed<boolean>(
    () => this.loadingSignal() || this.contentResource.isLoading(),
  );

  async ngOnInit() {
    this.sub.push(
      this.autoSave$
        .pipe(
          debounceTime(1000),
          filter(() => this.autoSave() && !!this.references()?.length),
        )
        .subscribe(() => this.save({ autosave: true })),
    );
    this.cmsLayoutService.setTitle(`Create Help Resource`);
  }

  ngOnDestroy(): void {}

  connectResource(content: ICMSContent, config?: { isAdd?: boolean; isRemove?: boolean }) {
    const thisContent = this.content();
    if (thisContent.id == content.id) {
      this._content.update(({ subHelpGuideContent, ...map }) => ({ ...content, subHelpGuideContent }));
    } else {
      const stagedAddEvent = this.stagedAddEvent();
      const existingIndex = thisContent.subHelpGuideContent?.findIndex(
        (x) => x.id == stagedAddEvent.previousContent.id,
      );

      if (config?.isRemove) {
        this._content.update(({ subHelpGuideContent, ...map }) => {
          subHelpGuideContent = subHelpGuideContent || [];
          subHelpGuideContent.splice(existingIndex, 1);
          return { ...map, subHelpGuideContent: subHelpGuideContent.concat([]) };
        });
        lastValueFrom(
          this.service.deleteHelpGuideContentRelationship({
            childId: content.id,
            parentId: this.content().id,
          }),
        );
      } else if (content.id == stagedAddEvent.previousContent.id) {
        this._content.update(({ subHelpGuideContent, ...map }) => {
          subHelpGuideContent = subHelpGuideContent || [];
          subHelpGuideContent[existingIndex] = content;
          return { ...map, subHelpGuideContent: subHelpGuideContent.concat([]) };
        });
      } else {
        this._content.update(({ subHelpGuideContent, ...map }) => {
          subHelpGuideContent = subHelpGuideContent || [];
          subHelpGuideContent.splice(existingIndex + 1, 0, content);
          return { ...map, subHelpGuideContent: subHelpGuideContent.concat([]) };
        });
      }
    }
    if (config?.isRemove) this.uS.notify(`Removed ${content.title}`, 2);
    else if (config?.isAdd) this.uS.notify(`Added ${content.title}`, 2);
    else this.uS.notify(`Updated ${content.title}`, 2);
    this.autoSave$.next(true);
  }

  async createdContent(content: ICMSContent) {
    this._content.set({ ...content, subHelpGuideContent: this.resourceSuffix() || [] });
    this.query.set({ helpGuideContentId: content.id });
    const reference = this.reference();
    if (reference) {
      try {
        await lastValueFrom(
          this.service.createHelpGuideContentReferencing({ ...reference, helpGuideContentId: content.id }),
        );
      } catch (error) {
        this.uS.info(error, 0);
      }
    }
  }

  openAdd(event: IAddCMSContentEvent) {
    this.stagedAddEvent.set(event);
    this.saveContentModalRef().open();
  }

  openEdit(event: IAddCMSContentEvent) {
    this.stagedAddEvent.set(event);
    this.selectedContent.set(event.previousContent);
    this.editContentModalRef().open();
  }
  async removeItem(event: IAddCMSContentEvent) {
    try {
      this.stagedAddEvent.set(event);
      const proceed = await this.uS.confirm(`Remove Content Block?`);
      if (!proceed) return;
      this.connectResource(event.previousContent, { isRemove: true });
    } catch (error) {
      this.uS.info(0, error);
    }
  }

  openSelectBlock(event: IAddCMSContentEvent) {
    this.stagedAddEvent.set(event);
    this.selectContentModalRef().open();
  }
  protected refreshReferences() {
    this.referencesRefresher.set(performance.now());
  }
  async deleteReference(ref: ICMSReference) {
    try {
      const proceed = await this.uS.confirm(
        `You are about to delete ${Object.entries(ref)
          .map((x) => `${x[0]}: ${x[1]}`)
          .join('\n')}\nProceed?`,
      );
      if (!proceed) return;
      await lastValueFrom(this.service.deleteHelpGuideContentReferencing(ref.id));
      this.refreshReferences();
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  async save(config?: { autosave?: boolean }) {
    if (config?.autosave) this.autoSaving.set(true);
    else this.setLoader(true);
    try {
      const { subHelpGuideContent, ...parent } = this.content();
      if (!this.references()?.length) throw `Kindly assign the content to a reference`;
      const { content: relationships } = await lastValueFrom(
        this.service.searchContentRelationship({ parentId: parent.id }),
      );
      const relationshipsMap = relationships.toMap('childId');
      await lastValueFrom(
        this.service.createOrUpdateAllHelpGuideContentRelationship(
          subHelpGuideContent?.map((c, index) => ({
            childId: c.id,
            order: index,
            parentId: parent.id,
            id: relationshipsMap[c.id]?.id,
          })) || [],
        ),
      );

      if (!config?.autosave) {
        this.saved.emit();
        if (!this.isComponent())
          this.router.navigate(['../view'], {
            relativeTo: this.route,
            queryParams: this.query(),
          });
      }
    } catch (error) {
      this.uS.info(error, 0);
      this.setLoader(false);
    }
    if (config?.autosave) this.autoSaving.set(false);
  }
}

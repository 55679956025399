import { Component, computed, input, output, signal, TemplateRef } from '@angular/core';
import CMSFindParentContentsComponent from '../../cms-content-bank/cms-find-parent-contents/cms-find-parent-contents.component';
import { CommonModule } from '@angular/common';
import { ICMSContent, ICMSReference, ICMSResourceQuery } from '../../cms.model';
import ViewCMSResourceComponent from '../view-cms-resource/view-cms-resource.component';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { UtilityService } from '../../../../../Services/utility.service';
import { lastValueFrom } from 'rxjs';
import { CMSService } from '../../cms.service';
import { TranslatePipe } from '../../../../../Shared/pipes/translate.pipe';
import { IRowOption } from '../../../../../Shared/models/index.model';

@Component({
  selector: 'map-cms-resource',
  imports: [
    CMSFindParentContentsComponent,
    TranslatePipe,
    CommonModule,
    BtnComponent,
    ViewCMSResourceComponent,
  ],
  templateUrl: './map-cms-resource.component.html',
  styleUrl: './map-cms-resource.component.scss',
})
export class MapCMSResourceComponent {
  readonly reference = input.required<Omit<ICMSReference, 'id'>>();
  readonly applicationCode = input.required<string>();
  readonly referenceItemLabel = input.required<string>();
  readonly toolbarTemplate = input<TemplateRef<any>>();

  readonly mapped = output<ICMSReference>();

  protected _selectedContent = signal<ICMSContent>(null);

  protected query = computed<ICMSResourceQuery>(() =>
    this._selectedContent() ? { helpGuideContentId: this._selectedContent()?.id } : null,
  );

  protected loading = signal(false);
  protected computedHeader = computed<string>(() => `Map resource to ${this.referenceItemLabel() || ''}`);
  protected rowOptions = computed<IRowOption<ICMSContent>[]>(() => [
    {
      t: `Map to ${this.referenceItemLabel()}`,
      action: (row) => {
        this.mapSelectedContent(row);
      },
      iconClass: 'fa fa-plus',
      itemClass: 'text-success',
    },
  ]);

  constructor(
    public uS: UtilityService,
    public service: CMSService,
  ) {}

  protected async mapSelectedContent(selectedContent: ICMSContent) {
    this.loading.set(true);
    try {
      // debugger;
      const res = await lastValueFrom(
        this.service.createHelpGuideContentReferencing({
          ...this.reference(),
          helpGuideContentId: selectedContent.id,
        }),
      );
      this.mapped.emit(res);
      this.uS.notify(`${selectedContent.title} has been mapped to ${this.referenceItemLabel()}`);
    } catch (error) {
      this.uS.info(error, 0);
    }
    this.loading.set(false);
  }
}

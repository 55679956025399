import { CommonModule } from '@angular/common';
import { Component, inject, input, model } from '@angular/core';
import { ICMSContent, ICMSResource } from '../../cms.model';
import { AdminDocumentService } from 'projects/evolutics-admin-ui/src/app/shared/pages/documents/document.service';
import { RenderCMSContentService } from './render-cms-content.service';

@Component({
  template: '',
})
export class CMSContentTypeComponent {
  readonly content = model.required<ICMSResource>();

  readonly service = inject(RenderCMSContentService);

  updateContent(data: Partial<ICMSResource>) {
    this.content.update((content) => ({ ...content, ...data }));
  }
}

<loader [loading]="loadingSignal()">
  @if (form(); as form) {
    <div class="">
      <div class="row justify-content-center">
        <div class="col-12 mb-2">
          <h4 class="text-primary">{{ 'Content Type' | appTranslate | async }}</h4>
          <mat-button-toggle-group [formControl]="form.controls.type" aria-label="Content type">
            @for (type of service.types(); track type.code) {
              <mat-button-toggle [value]="type.code">{{ type.title }}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        </div>
        <div class="col-12"></div>
        @if (contentType()) {
          <div class="{{ selectedTypeMap().titleColClass || 'col-lg' }}">
              <app-input
                [label]="selectedTypeMap().titleLabel || 'Title'"
                [form]="form"
                name="title"
                [noFormat]="true"
                [showValidationMsg]="true" />
          </div> 
          @if (hasMetaFormSchemaVisible()) {
            <div class="col-12">
              <ng-container *ngTemplateOutlet="metaTempl" />
            </div>
          }
          @switch (contentType()) {
            @case (eCMSType.file) {
              <div class="col-12">
                <div class="row g-3 justify-content-center">
                  <div class="col-lg-4">
                    <ng-container *ngTemplateOutlet="uploadTempl" />
                  </div>
                </div>
              </div>
            }
            @case (eCMSType.accordion) {
              <div class="col-12">
                <mat-accordion
                  cdkDropList
                  [cdkDropListData]="form.controls._accordionRows.controls"
                  (cdkDropListDropped)="reorderItems(form.controls._accordionRows, $event)">
                  @for (subForm of form.controls._accordionRows.controls; track subForm) {
                    <div class="row align-items-center g-3" cdkDrag>
                      <div class="col-auto">
                        <mat-icon class="pointer opacity-50" cdkDragHandle>drag_handle</mat-icon>
                      </div>
                      <div class="col">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            {{ $index + 1 }}. {{ subForm.controls.title.value || 'New Item' }}
                          </mat-expansion-panel-header>
                          <ng-template matExpansionPanelContent>
                            <app-input
                              [label]="'Title'"
                              [form]="subForm"
                              name="title"
                              [noFormat]="true"
                              [showValidationMsg]="true" />
                            <rich-text-editor-rf label="Body" [form]="subForm" name="text" />
                          </ng-template>
                        </mat-expansion-panel>
                      </div>
                      <div class="col-auto">
                        <row-actions
                          (clickedAdd)="
                            service.addFormItem(
                              form.controls._accordionRows,
                              service.accordionRowFS
                            )($index + 1)
                          "
                          (clickedDelete)="service.removeFormItem(form.controls._accordionRows)($index)" />
                      </div>
                    </div>
                  }
                </mat-accordion>
                <add-item
                  addText="Add accordion item"
                  [formArray]="form.controls._accordionRows"
                  (clickedAdd)="
                    service.addFormItem(form.controls._accordionRows, service.accordionRowFS)(0)
                  " />
              </div>
            }
            @case (eCMSType.image) {
              <div class="col-12">
                <div class="row g-3 align-items-start justify-content-center">
                  <div class="col-lg-4">
                    <ng-container *ngTemplateOutlet="uploadTempl" />
                  </div>
                  @if (imagePreview()) {
                    <div class="col-lg-8">
                      <div class="image-preview-case">
                        <img class="image-preview" [src]="imagePreview()" alt="Image Preview" />
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
            @case (eCMSType.table) {
              <div class="col-12">
                <div class="row g-3 align-items-start justify-content-center">
                  <div class="col-lg-{{ formValue().columns?.length ? 3 : 12 }}">
                    <ng-container *ngTemplateOutlet="uploadTempl" />
                  </div>
                  @if (formValue().columns?.length) {
                    <div class="col-lg-9">
                      <h5>{{ 'Table Preview' | appTranslate | async }}</h5>
                      <render-cms-table [content]="formValue() | toAny" />
                    </div>
                  }
                </div>
              </div>
              <!-- <div class="col-12">
                <table class="table">
                  <thead>
                    <tr
                      cdkDropList
                      [cdkDropListData]="form.controls.columns.controls"
                      (cdkDropListDropped)="reorderItems(form.controls.columns, $event)">
                      <th class="w-1"></th>
                      @for (subForm of form.controls.columns.controls; track subForm) {
                        <th>
                          <app-input
                            [noFormat]="true"
                            placeholder="Column {{ $index + 1 }}"
                            [form]="subForm"
                            name="t"
                            type="text"
                            [showValidationMsg]="false" />
                        </th>
                      }
                      <th class="w-1">
                        <row-actions
                          matTooltip="Columns"
                          (clickedAdd)="service.addFormItem(form.controls.columns, service.columnFS)(1)"
                          (clickedDelete)="service.removeFormItem(form.controls.columns)(0)" />
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    cdkDropList
                    [cdkDropListData]="form.controls.rows.controls"
                    (cdkDropListDropped)="reorderItems(form.controls.rows, $event)">
                    @for (rowForm of form.controls.rows.controls; track rowForm; let rowIndex = $index) {
                      <tr>
                        <td class="w-1">
                          <mat-icon class="pointer opacity-50" cdkDragHandle>drag_handle</mat-icon>
                          {{ rowIndex + 1 }}
                        </td>
                        @for (columnForm of form.controls.columns.controls; track columnForm) {
                          <td>
                            <app-input
                              [noFormat]="true"
                              placeholder="Column {{ $index + 1 }}"
                              [form]="rowForm"
                              name="t"
                              type="text"
                              [showValidationMsg]="false" />
                          </td>
                        }

                        <td class="w-1">
                          <row-actions
                            matTooltip="Rows"
                            (clickedAdd)="service.addFormItem(form.controls.rows, service.rowFS)($index + 1)"
                            (clickedDelete)="service.removeFormItem(form.controls.rows)($index)" />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                <add-item
                  addText="Add row"
                  [formArray]="form.controls.rows"
                  (clickedAdd)="service.addFormItem(form.controls.rows, service.rowFS)(0)" />
              </div> -->
            }
            @case (eCMSType.url) {
              <div class="col-lg-9">
                <app-input
                  [noFormat]="true"
                  label="URL"
                  [form]="form"
                  [placeholder]="service.urlFormat()"
                  name="url"
                  type="text"
                  [showValidationMsg]="true" />
              </div>
            }
            @default {
              <div class="col-lg-12">
                <rich-text-editor-rf label="Body" [form]="form" name="text" />
              </div>
            }
          }
        }
      </div>
    </div>
    <div class="mt-3">
      <div class="row g-3 row-cols-auto justify-content-end">
        @if (isCreate() && !noConsecutiveCreation()) {
          <div class="">
            <app-btn [form]="form" (mclick)="submit(true, srBtn)" group="save" text="Save and Reset" #srBtn />
          </div>
        }
        <div class="">
          <app-btn
            [form]="form"
            (mclick)="submit(false, sBtn)"
            group="save"
            text="{{ isCreate() || isComponent() ? 'Save' : 'Update' }}"
            #sBtn />
        </div>
        <div class="col-12">
          <form-error [form]="form" />
        </div>
      </div>
    </div>
    <ng-template #uploadTempl>
      <div class="upload-case" libDragDropFileUpload (fileDropped)="parseUpload($event[0])" #cont>
        <div class=" ">
          <app-file-upload
            #fu
            class=" "
            [accept]="uploadAcceptType()"
            (fileChange)="parseUpload($event)"
            label="Select a file" />
          <div class="or">{{ 'OR' | appTranslate | async }}</div>
          <div class="or-text">
            {{ 'Drag and drop a file' | appTranslate | async }}
          </div>
        </div>
      </div>
      @if (formValue()?.file) {
        <div class="mt-3 d-flex justify-content-center gap-3">
          <app-btn
            text="Clear"
            icon="cancel"
            type="danger-outline"
            (mclick)="form.controls.file.reset(); fu.removeFile(0)" />
        </div>
      } @else if (contentType() == eCMSType.table && formValue().columns?.length) {
        <div class="mt-3 d-flex justify-content-center gap-3">
          <app-btn
            text="Clear"
            icon="cancel"
            type="danger-outline"
            (mclick)="form.controls.columns.reset(); form.controls.rows.reset(); fu.removeFile(0)" />
        </div>
      }
    </ng-template>
    <ng-template #metaTempl>
      <form-generator
        [gridMDStyle]="3"
        [gridLGStyle]="4"
        [gridXXLStyle]="6"
        [form]="form.controls.meta"
        [formSchema]="metaFormSchema()"
        [showSubmitBtn]="false" />
    </ng-template>
    <!-- @if (isComponent() && formValue()?.id) {
    <div class="d-flex center">
      <app-btn text="Add" icon="add" [matMenuTriggerFor]="addMenu" />

      <mat-menu #addMenu="matMenu">
        @for (type of service.types(); track type.code) {
          <button mat-menu-item (click)="addChild.emit({type:type.code})">{{ type.title }}</button>
        }
      </mat-menu>
    </div>
  } -->
  }
</loader>

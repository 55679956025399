<div class="content-block" [id]="id()">
  <render-cms-content
    [isRoot]="isRoot()"
    [content]="content()"
    class="{{ service.allowEdit() && !isRoot() ? 'border rounded-10 p-2 my-1' : '' }}"
    [ngClass]="{ allowEdit: service.allowEdit() }" />
  @if (service.allowEdit()) {
    <cms-resource-item-actions [content]="content()" [isRoot]="isRoot()" />
  }
</div>
@if (content().subHelpGuideContent?.length) {
  <div
    cdkDropList
    [cdkDropListDisabled]="!service.allowEdit()"
    (cdkDropListDropped)="service.drop($event, content().subHelpGuideContent)">
    @if (service.allowSubRemapping() && service.allowEdit()) {
      @for (subContent of content().subHelpGuideContent; track subContent.id) {
        <div class="d-flex gap-8">
          <mat-checkbox (change)="service.toggleContentBlockSelection($event.checked, subContent)"></mat-checkbox>
          <cms-resource-item class="w-100" [content]="subContent" cdkDrag cdkDragLockAxis="y" />
        </div>
      }
    } @else {
      @for (subContent of content().subHelpGuideContent; track subContent.id) {
        <cms-resource-item [content]="subContent" cdkDrag cdkDragLockAxis="y" />
      }
    }
  </div>
}

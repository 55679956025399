<div class="content-title" [innerHTML]="content().title"></div>
<mat-accordion>
  @for (row of content().rows || []; track row.title) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        {{ row.title }}
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="" [innerHTML]="row.text"></div>
      </ng-template>
    </mat-expansion-panel>
  }
</mat-accordion>

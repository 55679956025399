import { computed, Injectable } from '@angular/core';
import { ApiService } from '../../../Services/api.service';
import {
  ICMSContent,
  ICMSReference,
  ICMSRelationship,
  ICMSResource,
  ISearchCMSContent,
  ECMSType,
  ICMSResourceQuery,
  ECMSContentRefCat,
  ECMSModuleType,
} from './cms.model';
import { environment } from '../../../../environments/environment';
import {
  CustomValidationError,
  ELanguage,
  ICodeTitle,
  IGetQuery,
  IObjectLiteral,
  ISearchResponse2,
  ITableCol,
} from 'ets-fe-ng-sdk';
import { forkJoin, lastValueFrom, map, mergeMap, of, shareReplay, switchMap } from 'rxjs';
import { UtilityService } from '../../../Services/utility.service';
import { IBaseFormSchema } from 'ets-fe-ng-sdk/lib/Shared/components/form-generator/form-generator.base.component';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { UrlSegment } from '@angular/router';
import { uniq, uniqBy } from 'lodash-es';
import { ISearchResponse } from '../../../Shared/components/search/search-extras/search.interface';

@Injectable({ providedIn: 'root' })
export class CMSService {
  protected readonly baseURL = environment.apiBaseUrl + `/admin/rest/help/guide/content`;
  readonly languages = computed<ICodeTitle<ELanguage>[]>(() => [
    { code: ELanguage.EN, title: 'English' },
    { code: ELanguage.FR, title: 'French' },
  ]);
  readonly sharedFormSchema = computed<
    Record<
      'content' | 'type' | 'refCat' | 'moduleType',
      Omit<IBaseFormSchema<any, any>, 'field' | 'children'>
    >
  >(() => ({
    content: {
      label: 'Content',
      type: 'autocomplete',
      optionsFunc: this.searchHelpGuideContentByTitle,
      valueField: 'id',
      labelField: 'title',
    },
    moduleType: {
      label: 'Module Type',
      type: 'select',
      options: this.moduleTypes(),
      valueField: 'code',
      labelField: 'title',
    },
    refCat: {
      label: 'Reference Category',
      type: 'select',
      options: this.refCats(),
      valueField: 'code',
      labelField: 'title',
    },
    type: {
      label: 'Content Type',
      type: 'select',
      options: this.types(),
      valueField: 'code',
      labelField: 'title',
    },
  }));
  readonly urlFormat = computed(() => 'https://');
  readonly moduleTypes = computed(() =>
    Object.values(ECMSModuleType).map<ICodeTitle<ECMSModuleType>>((v) => ({
      code: v,
      title: v.length > 3 ? this.uS.toTitleCase(v) : v,
    })),
  );
  readonly types = computed(() =>
    Object.values(ECMSType).map<ICodeTitle<ECMSType>>((v) => ({ code: v, title: this.uS.toTitleCase(v) })),
  );
  readonly refCats = computed(() =>
    Object.values(ECMSContentRefCat).map<ICodeTitle<ECMSContentRefCat>>((v) => ({
      code: v,
      title: this.uS.toTitleCase(v.split('_').join(' ')),
    })),
  );

  /**
   * Upload maximum size in bytes
   */
  readonly fileMaximumSize = 10000000;
  constructor(
    protected apiS: ApiService,
    public uS: UtilityService,
  ) {}
  buildContentResource(contents: ICMSContent[]): ICMSResource[] {
    return [];
  }

  get contentFS() {
    return new FormGroup({
      // _addedTime: new FormControl<number>(performance.now()),
      // _id: new FormControl<string>(this.uS.generateUUID()),
      // _order: new FormControl<number>(null),
      _parentID: new FormControl<string>(null),
      columns: new FormControl<string[]>(null),
      docId: new FormControl<string>(null),
      applicationCode: new FormControl<string>(null),
      file: new FormControl<File>(null),
      id: new FormControl<string>(null),
      language: new FormControl<string>(null),
      originalId: new FormControl<string>(null),
      _accordionRows: new FormArray<typeof this.accordionRowFS>([]),
      rows: new FormControl<IObjectLiteral[]>(null),
      text: new FormControl<string>(null),
      title: new FormControl<string>(null),
      meta: new FormGroup({
        fileExtension: new FormControl<string>(null),
        hideTitle: new FormControl<boolean>(null),
        displayFileContent: new FormControl<boolean>(null),
      }),
      type: new FormControl<ECMSType>(ECMSType.text, Validators.required),
      url: new FormControl<string>(null, (control): CustomValidationError => {
        const val = control.value as string;
        return val != null && !val.startsWith(this.urlFormat())
          ? { custom: `URL should start with ${this.urlFormat()}` }
          : null;
      }),
      versionNo: new FormControl<string>(null),
    });
  }

  get contentParentFS() {
    return new FormGroup({
      columns: new FormControl<string[]>(null),
      docId: new FormControl<string>(null),
      file: new FormControl<File>(null),
      id: new FormControl<string>(null),
      language: new FormControl<string>(null),
      subHelpGuideContent: new FormArray<typeof this.contentFS>(null),
      originalId: new FormControl<string>(null),
      _accordionRows: new FormArray<typeof this.accordionRowFS>([]),
      rows: new FormControl<IObjectLiteral[]>(null),
      text: new FormControl<string>(null),
      title: new FormControl<string>(null),
      meta: new FormGroup({
        fileExtension: new FormControl<string>(null),
      }),
      type: new FormControl<ECMSType>(ECMSType.text, Validators.required),
      url: new FormControl<string>(null, (control): CustomValidationError => {
        const val = control.value as string;
        return val != null && !val.startsWith(this.urlFormat())
          ? { custom: `URL should start with ${this.urlFormat()}` }
          : null;
      }),
      versionNo: new FormControl<string>(null),
    });
  }

  get columnFS() {
    return new FormGroup({
      t: new FormControl<string>(null),
    });
  }
  get accordionRowFS() {
    return new FormGroup({
      title: new FormControl<string>(null),
      text: new FormControl<string>(null),
      value: new FormControl<string>(null),
      // cells: new FormArray<typeof this.cellFS>(null),
    });
  }
  get cellFS() {
    return new FormGroup({
      value: new FormControl<string>(null),
    });
  }

  addFormItem =
    <T extends AbstractControl>(formArray: FormArray<T>, sampleForm: T) =>
    (index: number, data?: T['value']) => {
      // debugger;
      const form = sampleForm;
      if (data) form.patchValue(data);
      formArray.insert(index, form);
    };
  removeFormItem = (formArray: FormArray) => (index: number) => {
    formArray.removeAt(index);
  };

  createHelpGuideContent(content: ICMSContent, file?: File) {
    const fd = new FormData();
    fd.set('createHelpGuideContentStr', JSON.stringify(content));
    if (file) fd.set('file', file);
    return this.apiS.postFile<ICMSContent>(this.baseURL, fd);
  }

  updateHelpGuideContent(content: ICMSContent) {
    return this.apiS.put<ICMSContent>(`${this.baseURL}/${content.id}`, content);
  }

  deleteHelpGuideContent(contentID: string, config: { children?: boolean; force?: boolean }) {
    return this.apiS.delete(`${this.baseURL}/${contentID}` + this.apiS.getRequestParse(config));
  }

  getContentResource(query: ICMSResourceQuery) {
    return this.apiS.get<ICMSResource>(this.baseURL + '/details', query);
  }
  async getPageRelatedResources(query: ICMSResourceQuery) {
    // debugger;
    try {
      let chunks: string[] = [query.refId],
        pathRTL: string = '';
      const pathChunks = query.refId.split('/');
      for (const chunk of pathChunks.reverse())
        if (chunk) {
          // debugger;
          pathRTL = pathRTL ? chunk + '/' + pathRTL : chunk;
          chunks.push('/' + chunk, '/' + pathRTL);
        }
      pathRTL = '';
      // debugger;
      pathChunks.pop();
      pathChunks.pop();
      for (const chunk of pathChunks.reverse())
        if (chunk) {
          // debugger;
          pathRTL = pathRTL + '/' + chunk;
          chunks.push(  pathRTL);
        }
      chunks = uniq(chunks.reverse());
      const results = await lastValueFrom(
        forkJoin(
          chunks.map((chunk) =>
            this.searchContentReference({ ...query, refId: chunk, pageNumber: 1, pageSize: 10 }),
          ),
        ),
      );
      const distinct =
        uniqBy(
          results.flatMap((x) => x.content),
          (i) => i.helpGuideContentId,
        ) || [];
      return lastValueFrom(
        forkJoin(
          distinct.map((c) =>
            this.getContentById(c.helpGuideContentId).pipe(map((content) => ({ content, reference: c }))),
          ),
        ),
      );
    } catch (error) {
      return [];
    }
  }

  getContentById(id: string) {
    return this.apiS.get<ICMSContent>(this.baseURL + '/' + id);
  }

  getNoneTranslatedHelpGuideContentByType(query: {
    language: ELanguage;
    pageNumber: number;
    pageSize: number;
    type: string;
  }) {
    return this.apiS.get<ICMSContent[]>(this.baseURL + '/non-translated', query);
  }

  createHelpGuideContentReferencing(data: ICMSReference) {
    data.id = data.id || this.uS.generateUUID();
    return this.apiS.post<ICMSReference>(this.baseURL + '/referencing', data);
  }

  updateHelpGuideContentReferencing(data: ICMSReference) {
    return this.apiS.put(`${this.baseURL}/referencing/${data.id}`, data);
  }

  deleteHelpGuideContentReferencing(id: string) {
    return this.apiS.delete(`${this.baseURL}/referencing/${id}`);
  }

  createOrUpdateAllHelpGuideContentRelationship(data: ICMSRelationship[]) {
    return this.apiS.post<ICMSRelationship[]>(this.baseURL + '/relationship', data);
  }

  searchContentRelationship = (query: IGetQuery<Pick<ICMSRelationship, 'childId' | 'parentId'>>) => {
    return this.apiS.get<ISearchResponse2<ICMSRelationship>>(this.baseURL + '/relationship/search', query);
  };

  searchContentReference = (query: IGetQuery<ICMSReference>) => {
    return this.apiS.get<ISearchResponse2<ICMSReference>>(this.baseURL + '/referencing/search', query);
  };
  getContentReference = (query: IGetQuery<ICMSReference>) => {
    return this.searchContentReference(query).pipe(map((r) => r.content?.[0]));
  };

  searchParentContent = (query: IGetQuery) => {
    return this.apiS.get<ISearchResponse2<ICMSContent>>(this.baseURL + '/distinct-relationship', query);
  };

  deleteHelpGuideContentRelationship(query: Pick<ICMSRelationship, 'childId' | 'parentId'>) {
    return this.searchContentRelationship({ childId: query.childId, parentId: query.parentId }).pipe(
      mergeMap(({ content }) =>
        content[0] ? this.deleteHelpGuideContentRelationshipById(content[0].id) : of(null),
      ),
    );
  }

  deleteHelpGuideContentRelationshipById(id: string) {
    return this.apiS.delete(`${this.baseURL}/relationship/${id}`);
  }

  searchHelpGuideContent = (query: ISearchCMSContent) =>
    this.apiS.get<ISearchResponse2<ICMSContent>>(this.baseURL + '/search', query);

  searchHelpGuideContentUsingIndex = (query: IGetQuery<{ searchKey: string; applicationCode: string }>) =>
    this.apiS.get<ISearchResponse2<ICMSContent>>(this.baseURL + '/index-search', query);

  // searchHelpGuideContentUsingIndex = (query: IGetQuery<{ searchKey: string }>) =>
  //   this.searchHelpGuideContent({ ...query, title: query.searchKey });

  searchHelpGuideContentByTitle = (title: string) =>
    this.searchHelpGuideContent({ title, pageSize: 20, pageNumber: 1 }).pipe(map((r) => r?.content));
}

@if (content().title) {
  <div class="content-title">
    {{ content().title }}
  </div>
}

@if (showFilter()) {
  <mat-form-field>
    <mat-label>{{ 'Search' | appTranslate | async }}</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
  </mat-form-field>
}

<div class="overflow-auto border rounded-10">
  <div class="overflow-auto">
    <table mat-table [dataSource]="dataSource()" matSort>
      @for (col of columns(); track col) {
        <ng-container [matColumnDef]="col">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col | appTranslate | async }}</th>
          <td mat-cell *matCellDef="let row">{{ row[col] }}</td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columns()"></tr>
      <tr mat-row *matRowDef="let row; columns: columns()"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="columns().length">
          {{ noDataMessage() | appTranslate | async }}
        </td>
      </tr>
    </table>
  </div>

  @if (showPager()) {
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page" />
  }
</div>

import { Component, computed, effect, inject, input } from '@angular/core';
import CMSResourceExplorerComponent from '@Reusables/reusable-pages/cms/cms-resource/cms-resource-explorer/cms-resource-explorer.component';

import { ECMSContentRefCat, ECMSModuleType, ICMSReference } from '@Reusables/reusable-pages/cms/cms.model';
import { environment } from '@environments/environment';
import { AppService } from '@Services/app.service';
import { CMSResourceExoplorerParentBaseComponent } from '@Reusables/reusable-pages/cms/cms-resource/cms-resource-explorer/cms-resource-exoplorer-parent-base/cms-resource-exoplorer-parent-base.component';

@Component({
  selector: 'coreapp-client-help',
  imports: [CMSResourceExplorerComponent],
  templateUrl: './client-help.component.html',
  styleUrl: './client-help.component.scss',
})
export default class ClientHelpComponent extends CMSResourceExoplorerParentBaseComponent {
  readonly appService = inject(AppService);

  readonly configMainMenu = computed(() => this.appService.topMenu);
  // readonly configMainMenu = computed(()=>configMainMenu);
  readonly applicationCode = computed(() => 'EV');
  readonly baseQuery = computed<Partial<ICMSReference>>(() => ({
    applicationCode: 'EV',
    // refCat: ECMSContentRefCat.menuItem,
    refCat: ECMSContentRefCat.path,
    moduleType: ECMSModuleType.help,
  }));

  // d = effect(() => {
  //   this.configMainMenu();
  //   debugger;
  // });
  ngOnInit(): void {}

  readonly allowEdit = computed<boolean>(() => {
    const user = environment.user;
    // return false;
    return (
      user?.email &&
      (user.email.startsWith('ahmed@') ||
        user.email.startsWith('osagie@') ||
        user.email.startsWith('miller@') ||
        user.email.startsWith('janet@') ||
        user.email.startsWith('linda@'))
    );
  });
}

<loader [loading]="loadingSignal()">
  <ng-template #_toolbarEndTemplate>
    @if (selectedReferenceTreeItem()) {
      <a class="search-home" routerLink="./">
        <i class="fa fa-search"></i>
      </a>
    }
    <a routerLink="/">
      <i class="fa fa-home" aria-hidden="true"></i>
      {{ 'Home' | appTranslate | async }}
    </a>
  </ng-template>

  <ng-template #resourceCreationControlTemplate>
    @if (viewMode() && viewMode() != ViewMode.viewing) {
      <app-btn text="Cancel" type="danger" icon="cancel" (mclick)="setViewMode(ViewMode.viewing)" />
    }
    @if (showSubMapButton()) {
      <app-btn text="Map Selection" type="success" icon="add" (mclick)="submapperModal.open()" />
    }
  </ng-template>

  <ng-template #noResponseIconTemplate>
    <span class="sad-icon">😔</span>
  </ng-template>

  <ng-template #sidenavTemplate>
    <mat-tree
      #referenceTreeEl
      [dataSource]="referenceTree()"
      [childrenAccessor]="childrenAccessor"
      class="reference-tree">
      <ng-template #nodeTempl let-node>
        <div class="d-flex align-items-center">
          <!-- <mat-icon class="mat-icon-rtl-mirror"> chevron_right
            </mat-icon> -->
          @if (node.queryParams) {
            @if (mini()) {
              <a
                (click)="selectedReferenceTreeItem.set(node); toggleSideMenu()"
                class="reference-tree-item pointer"
                [ngClass]="{ active: selectedReferenceTreeItem()?.id == node.id }">
                @if (node.icon) {
                  <app-icon [icon]="node.icon" />
                } @else {
                  <div class="lead-block"></div>
                }
                {{ node.label }}
              </a>
            } @else {
              <a
                routerLink="./"
                routerLinkActive="active"
                class="reference-tree-item"
                [ngClass]="{ active: selectedReferenceTreeItem()?.id == node.id }"
                [queryParams]="node.queryParams">
                @if (node.icon) {
                  <app-icon [icon]="node.icon" />
                } @else {
                  <div class="lead-block"></div>
                }
                {{ node.label }}
              </a>
              @if (showSubMapButton()) {
                <i
                  class="fas fa-copy ms-8 pointer text-secondary"
                  [cdkCopyToClipboard]="node.link"
                  (click)="uS.notify('Copied ' + node.link)"></i>
              }
            }
          } @else {
            <span class="reference-tree-item">
              @if (node.icon) {
                <app-icon [icon]="node.icon" />
              } @else {
                <div class="lead-block"></div>
              }
              {{ node.label }}
            </span>
          }
        </div>
      </ng-template>
      <!-- This is the tree node template for leaf nodes -->
      <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
      <mat-nested-tree-node *matTreeNodeDef="let node">
        <ng-container *ngTemplateOutlet="nodeTempl; context: { $implicit: node }"></ng-container>
      </mat-nested-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        [cdkTreeNodeTypeaheadLabel]="node.label">
        <div class="mat-tree-node">
          <ng-container *ngTemplateOutlet="nodeTempl; context: { $implicit: node }"></ng-container>
          <button
            mat-icon-button
            matTreeNodeToggle
            id="{{ toggleBtnIDPrefix }}{{ node.id }}"
            [attr.aria-label]="'Toggle ' + node.label">
            <mat-icon class="mat-icon-rtl-mirror">
              {{ referenceTreeEl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </button>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.reference-tree-invisible]="!referenceTreeEl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </ng-template>

  <ng-template #searchCellTempl let-obj>
    <div class="search-result-item">
      <i class="fa fa-dot-circle result-icon"></i>
      <div>
        <a
          class="search-result-item-header"
          routerLink="./"
          [queryParams]="{ helpGuideContentId: obj.row.id }">
          {{ obj.row.title }}
        </a>

        <div class="search-result-item-body" [innerHTML]="obj.row.text | trimText: 100"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #searchCellMiniTempl let-obj>
    <div class="search-result-item">
      <i class="fa fa-dot-circle result-icon"></i>
      <div>
        <a
          class="search-result-item-header pointer"
          (click)="overrideQuery.set({ helpGuideContentId: obj.row.id })">
          {{ obj.row.title }}
        </a>

        <div class="search-result-item-body" [innerHTML]="obj.row.text | trimText: 100"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #fullSearchTemplate>
    <div class="mx-auto pt-3 help-search" style.width="{{ 'min(600px,80%)' }}">
      <div class="row align-items-center">
        <div class="col help-search-input">
          <app-input-basic
            [form]="searchForm"
            name="query"
            type="text"
            [noFormat]="true"
            placeholder="Search Library" />
        </div>
      </div>
      <find-item
        [searchIfNoQuery]="false"
        [hidden]="!searchQuery()"
        [pageSize]="5"
        searchPromptText="Kindly enter your search above"
        [striped]="false"
        [pageSize]="20"
        [useStaticLoader]="true"
        [customTableCellTemplates]="{ title: mini() ? searchCellMiniTempl : searchCellTempl }"
        [hidden]="false"
        [displayedColumns]="searchResponseColumn()"
        [formSchema]="searchFormSchema()"
        [showExport]="false"
        [showAdditionalColumns]="false"
        [searchObservableFunc]="searchHelpGuideContentUsingIndex()"
        [hideForm]="true"
        #searchFI />
    </div>
  </ng-template>

  <div class="h-100" [ngClass]="{ closed: !verticalNavTag.drawer().opened, mini: mini() }">
    <vertical-nav
      #verticalNavTag
      [menuItems]="referenceTree()"
      [toolbarTitle]="explorerTitle()"
      [sidenavTemplate]="sidenavTemplate"
      [showMenu]="true"
      [drawerMode]="mini() ? 'over' : null"
      [opened]="!mini()"
      [useToolbar]="!mini()"
      [toolbarEndTemplate]="toolbarEndTemplate() || _toolbarEndTemplate">
      <loader [loading]="loadingResource()">
        <!-- {{selectedReferenceTreeItem()|json}} -->
        @if (!selectedReferenceTreeItem() && !resource()) {
          <div class="container minh-100 center flex-column py-4">
            <div class="text-center" [innerHTML]="welcomeText() | appTranslate | async"></div>
            <div class="mt-3 w-100">
              <ng-container *ngTemplateOutlet="fullSearchTemplate"></ng-container>
            </div>
          </div>
        } @else {
          @if (resource() && viewMode() == ViewMode.viewing) {
            @if (allowEdit()) {
              <div class="px-2">
                <div>
                  <div class="mb-2 mx-0 row row-cols-lg-auto g-3 justify-content-end">
                    <app-btn text="Edit Resource" (mclick)="setViewMode(ViewMode.creating)" icon="edit" />
                    <app-btn
                      text="Delete Mapping"
                      [disabled]="!referenceResource.value()"
                      (mclick)="deleteMapping()"
                      type="danger"
                      icon="delete" />
                  </div>
                </div>
              </div>
            }
            <cms-resource [content]="resource()" />
          } @else if (allowEdit()) {
            @switch (viewMode()) {
              @case (ViewMode.creating) {
                <save-cms-resource
                  (saved)="refresh()"
                  [hideReferencing]="true"
                  [isComponent]="true"
                  [allowSubRemapping]="true"
                  (selectedContentBlockList)="selectedContentBlockList.set($event)"
                  [applicationCode]="applicationCode()"
                  [overrideQuery]="referenceResource.value()"
                  [toolbarTemplate]="resourceCreationControlTemplate"
                  [referenceItemLabel]="selectedReferenceTreeItem()?.label"
                  [reference]="contentResourceQuery()" />
              }
              @case (ViewMode.mapping) {
                <div class="container">
                  <map-cms-resource
                    [applicationCode]="applicationCode()"
                    (mapped)="refresh()"
                    [toolbarTemplate]="resourceCreationControlTemplate"
                    [referenceItemLabel]="selectedReferenceTreeItem()?.label"
                    [reference]="contentResourceQuery()" />
                </div>
              }
              @default {
                <div class="text-center d-flex flex-column align-items-center justify-content-center h-100">
                  <ng-container *ngTemplateOutlet="noResponseIconTemplate"></ng-container>
                  <div class="p-3">
                    {{ 'Help resource could not be found' | appTranslate | async }}
                  </div>
                  <div class="mt-3">
                    <div class="row row-cols-lg-auto align-items-center g-3">
                      <div class="">
                        <app-btn
                          icon="add"
                          text="Create New Resource"
                          (mclick)="setViewMode(ViewMode.creating)" />
                      </div>
                      <div class="">
                        <app-btn
                          icon="cogs"
                          text="Map Existing Resource"
                          (mclick)="setViewMode(ViewMode.mapping)" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            }
          } @else {
            <div class="container" [ngClass]="{ 'h-100 center flex-column': !searchQuery() }">
              <div class="">
                <ng-container *ngTemplateOutlet="noResponseIconTemplate"></ng-container>
              </div>
              <div
                class="text-center"
                [innerHTML]="
                  'Help resource could not be found.<br>Kindly check out our other resources.'
                    | appTranslate
                    | async
                "></div>
              <div class="mt-3" style.width="{{ 'min(90%,600px)' }}">
                <loader [loading]="loadingRelatedResources()" class="vh-10" text="Fetching related resources">
                  <div class="d-flex justify-content-center flex-column text-center">
                    <h6 class="mt-5 mb-0">
                      {{ 'Related resources' | appTranslate | async }}
                    </h6>
                    <div>
                      @for (item of relatedResources(); track item.id) {
                        <a routerLink="./" [queryParams]="item.queryParams">
                          {{ item.label | appTranslateNL | async }}
                        </a>
                      } @empty {
                        <div>
                          {{ 'There are no related resources' | appTranslateNL | async }}
                        </div>
                      }
                    </div>
                  </div>
                </loader>
              </div>
            </div>
          }
        }
      </loader>
      @if (!mini()) {
        <img src="assets/img/support.png" class="help-bg-icon" aria-hidden="true" />
      }
    </vertical-nav>
  </div>
</loader>

<modal-comp
  #submapperModal
  header="Selection Mapper"
  height="90%"
  [showFooter]="false"
  [disableClose]="true"
  width="calc(100% - 200px)">
  <ng-template modalBody>
    <cms-explorer-submapper
      (saved)="submapperModal.close()"
      [baseQuery]="baseQuery()"
      [applicationCode]="applicationCode()"
      [blocks]="selectedContentBlockList()" />
  </ng-template>
</modal-comp>

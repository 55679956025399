import { Component, input, viewChild } from '@angular/core';
import CMSResourceExplorerComponent from '../cms-resource-explorer.component';

@Component({
  selector: 'cms-resource-exoplorer-parent-base',
  imports: [],
  templateUrl: './cms-resource-exoplorer-parent-base.component.html',
  styleUrl: './cms-resource-exoplorer-parent-base.component.scss',
})
export class CMSResourceExoplorerParentBaseComponent {
  readonly mini = input<boolean>();
  readonly explorerRef = viewChild(CMSResourceExplorerComponent);

  toggleSideMenu() {
    this.explorerRef().toggleSideMenu();
  }
  goHome() {
    this.explorerRef().goHome();
  }
}

import { CommonModule } from '@angular/common';
import { Component, computed, input, model, output } from '@angular/core';
import { ECMSContentRefCat, ECMSModuleType, ICMSReference } from '../../cms.model';
import { ISearchFormSchema } from '../../../../reusable-comps/find-item/find-item.component';
import { BtnComponent, IFormSchema, IFormSchema2 } from 'ets-fe-ng-sdk';
import { SharedModule } from '../../../../../Shared/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminApplicationService } from 'projects/evolutics-admin-ui/src/app/Services/admin-application.service';
import { CMSService } from '../../cms.service';
import { UtilityService } from '../../../../../Services/utility.service';
import { lastValueFrom, map, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'save-content-reference',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './save-content-reference.component.html',
  styleUrl: './save-content-reference.component.scss',
})
export class SaveContentReferenceComponent {
  readonly isLandscape = input(false);
  readonly contentID = input<string>();
  readonly baseReference = input<ICMSReference>();
  readonly reference = model<ICMSReference>();
  readonly saved = output<ICMSReference>();

  readonly formSchema = computed<IFormSchema2<ICMSReference>[]>(() => {
    // debugger;
    const baseReference = this.baseReference();
    const ret: IFormSchema2<ICMSReference>[] = [
      {
        ...this.cmsService.sharedFormSchema().content,
        field: 'helpGuideContentId',
        label: 'Help Guide Content',
        value: this.contentID(),
        hidden: !!this.contentID(),
      },
      {
        ...this.adminApplicationService.formSchema(),

        field: 'applicationCode',
        label: 'Application Code',
      },
      {
        ...this.cmsService.sharedFormSchema().moduleType,
        field: 'moduleType',
        label: 'Module Type',
      },
      { ...this.cmsService.sharedFormSchema().refCat, field: 'refCat', label: 'Reference Category' },
      {
        field: 'refId',
        label: this.refCat() == ECMSContentRefCat.path ? `Path` : 'Reference ID',
        noFormat: true,
      },
    ];
    if (baseReference) ret.forEach((x) => (x.hidden = x.hidden || baseReference[x.field] != null));
    return ret;
  });

  readonly form = computed(() => {
    const form = new FormGroup({
      helpGuideContentId: new FormControl<string>(this.contentID(), Validators.required),
      refCat: new FormControl<ECMSContentRefCat>(null),
      refId: new FormControl<string>(null),
      id: new FormControl<string>(null),
      applicationCode: new FormControl<string>(null),
      moduleType: new FormControl<ECMSModuleType>(null),
    });
    if (this.reference()) form.patchValue(this.reference());
    else if (this.baseReference()) form.patchValue(this.baseReference());
    return form;
  });

  readonly formValue = toSignal(
    toObservable(this.form).pipe(
      switchMap((form) => form?.valueChanges.pipe(map(() => this.form().getRawValue()))),
    ),
  );
  readonly refCat = computed(() => this.formValue()?.refCat);

  constructor(
    public cmsService: CMSService,
    public adminApplicationService: AdminApplicationService,
    public uS: UtilityService,
  ) {}

  submit = async (formValue = this.form().getRawValue()) => {
    this.form().disable();
    if (formValue.refCat == ECMSContentRefCat.path && formValue.refId?.[0] != '/') {
      formValue.refId = '/' + formValue.refId;
      if (formValue.refId.length > 1 && formValue.refId.endsWith('/'))
        formValue.refId = formValue.refId.slice(0, formValue.refId.length - 1);
    }
    const res = await lastValueFrom(
      formValue.id
        ? this.cmsService.updateHelpGuideContentReferencing(formValue)
        : this.cmsService.createHelpGuideContentReferencing(formValue),
    );
    // this.uS.notify(`Reference saved successfully`, 1);
    this.saved.emit(res);

    this.form().enable();
  };
}

import { Component, input, computed, inject, output, model, signal } from '@angular/core';
import { SharedModule } from '../../../../../Shared/shared.module';
import { PageTemplateComponent } from '../../../../../Shared/components/page-template/page-template.component';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CMSService } from '../../cms.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  BtnComponent,
  FileUploadComponent,
  IFormSchema,
  IFormSchema2,
  ITableCol,
  RichTextEditorRFComponent,
} from 'ets-fe-ng-sdk';
import { ECMSType, IAddCMSContentEvent, ICMSContent } from '../../cms.model';
import { lastValueFrom, map, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import { DragDropFileUploadDirective } from '../../../../../Shared/directives/drag-drop-file-upload.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CdkDropList, CdkDrag, CdkDragDrop, moveItemInArray, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { RenderCMSTableComponent } from '../render-cms-content/render-cms-table/render-cms-table.component';
import Papa from 'papaparse';
import { AdminApplicationService } from 'projects/evolutics-admin-ui/src/app/Services/admin-application.service';

@Component({
  selector: 'save-cms-content',
  standalone: true,
  imports: [
    DragDropFileUploadDirective,
    MatButtonToggleModule,
    SharedModule,
    RichTextEditorRFComponent,
    FileUploadComponent,
    CdkDropList,
    CdkDrag,
    MatIconModule,
    CdkDragHandle,
    RenderCMSTableComponent,
  ],
  templateUrl: './save-cms-content.component.html',
  styleUrl: './save-cms-content.component.scss',
})
export default class SaveCmsContentComponent extends PageTemplateComponent {
  readonly service = inject(CMSService);
  readonly adminApplicationService = inject(AdminApplicationService);

  readonly isComponent = input<boolean>();
  readonly baseData = input<Partial<ICMSContent>>();
  readonly noConsecutiveCreation = input<boolean>();
  readonly id = input<string>();
  readonly content = model<ICMSContent | null>(null);
  // readonly addChild = output<IAddCMSContentEvent>();
  readonly saved = output<ICMSContent>();

  protected readonly typeMap = computed<{
    [t in ECMSType]?: { titleLabel?: string; titleColClass?: string; uploadAcceptType?: string };
  }>(() => ({
    [ECMSType.file]: { uploadAcceptType: this.displayFileContent() ? '.pdf' : null },
    [ECMSType.image]: { titleLabel: 'Caption', uploadAcceptType: 'image' },
    [ECMSType.table]: { titleLabel: 'Table Header', uploadAcceptType: '.csv' },
    [ECMSType.url]: { titleLabel: 'URL Label', titleColClass: 'col-lg-3' },
  }));

  readonly form = computed(() => {
    const form = this.service.contentFS;
    if (this.content()) {
      form.patchValue(this.content());
      this.content().rows?.forEach((row, i) => {
        this.service.addFormItem(form.controls._accordionRows, this.service.accordionRowFS)(i, row);
      });
    } else if (this.baseData()) {
      form.patchValue(this.baseData());
    }
    return form;
  });

  readonly formValue$ = toObservable(this.form).pipe(
    switchMap((form) =>
      form.valueChanges.pipe(
        startWith(form.getRawValue()),
        map(() => form.getRawValue()),
        shareReplay(1),
      ),
    ),
  );

  readonly metaFormSchema = computed<IFormSchema[]>(() => {
    const contentType = this.contentType();
    const ret: (IFormSchema2<ICMSContent['meta']> | IFormSchema2<ICMSContent>)[] = [
      {
        field: 'hideTitle',
        label: `Hide ${this.selectedTypeMap()?.titleLabel || 'Title'}`,
        type: 'checkbox',
        hidden: contentType == ECMSType.url,
      },
      {
        field: 'displayFileContent',
        label: 'Display File Content',
        type: 'checkbox',
        hidden: contentType != ECMSType.file,
      },
    ];
    if (!this.baseData()?.applicationCode)
      ret.unshift({
        label: 'Application',
        field: 'applicationCode',
        options: this.adminApplicationService.allApplications(),
        valueField: 'code',
        labelField: ['code', 'applicationName'],
        form: this.form() as any,
        type: 'select',
      });
    return ret;
  });

  readonly hasMetaFormSchemaVisible = computed<boolean>(() => this.metaFormSchema().some((x) => !x.hidden));

  readonly selectedTypeMap = computed(() => this.typeMap()[this.contentType()] || {});

  readonly formValue = toSignal(this.formValue$);
  readonly imagePreview = toSignal<string>(
    this.formValue$.pipe(
      switchMap(
        (fv) =>
          new Observable<string>((sub) => {
            if (fv.type != ECMSType.image || !fv.file) {
              sub.next(null);
              sub.complete();
            } else {
              const fr = new FileReader();

              fr.onload = () => {
                sub.next(fr.result as string);
                sub.complete();
              };

              fr.onerror = () => {
                sub.next(null);
                sub.complete();
              };
              fr.readAsDataURL(fv.file);
            }
          }),
      ),
    ),
  );
  readonly contentType = computed(() => this.formValue()?.type);
  readonly displayFileContent = computed(() => this.formValue()?.meta.displayFileContent);
  // readonly _id = computed(() => this.formValue()?._id);
  readonly uploadAcceptType = computed(() => this.selectedTypeMap()?.uploadAcceptType);
  readonly showTablePreview = computed(
    () => !!this.formValue().columns?.length || !!this.formValue().rows?.length,
  );
  readonly eCMSType = ECMSType;

  async ngOnInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.isComponent()) return;
    try {
      const id = this.queryParamsSignal()['id'];
      if (!id) return;
      this.setLoader(true);
      const content = await lastValueFrom(this.service.getContentById(id));
      this.content.set(content);
    } catch (error) {
      this.uS.info(error, 0);
      this.uS.back();
    }
    this.setLoader(false);
  }

  /**
   * Take csv file input and parse into JSON for bulk upload
   * @param file File data to be uploaded from
   * @returns
   */
  async parseUpload(file: File) {
    if (!file) return;

    try {
      if (this.contentType() == ECMSType.table) {
        this.parseTableUpload(file);
        return;
      }
      if (file.size > this.service.fileMaximumSize)
        throw `File exceeds the maximum size of ${Math.round(this.service.fileMaximumSize / 1000_000)}MB`;
      this.form().patchValue({ file, meta: { fileExtension: file.name.split('.').lastItem() } });
    } catch (error) {
      this.uS.info(error, 0);
    }
  }

  parseTableUpload(file: File) {
    this.setLoader(true);
    Papa.parse<any>(file, {
      worker: true,
      header: true,
      complete: (results) => {
        console.log('results', results);
        const form = this.form();
        form.patchValue({ columns: results.meta.fields, rows: results.data });
        this.setLoader(false);
      },
    });
  }

  reorderItems<T extends AbstractControl>(formArray: FormArray<T>, event: CdkDragDrop<T[]>) {
    moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
  }

  async submit(refresh: boolean, btn?: BtnComponent) {
    this.setLoader(true);
    btn?.setLoader(true);
    try {
      const { file, _accordionRows, ...formValue } = this.formValue();
      // formValue.rows =  formValue.rows .map(x=>x.title) as any;
      if (formValue.type == ECMSType.accordion) formValue.rows = _accordionRows;
      const res = await lastValueFrom(
        formValue.id
          ? this.service.updateHelpGuideContent(formValue)
          : this.service.createHelpGuideContent(formValue, file),
      );
      if (refresh) {
        // this.form().reset({ _id: this.uS.generateUUID() });
        this.form().controls._accordionRows.clear();
      } else if (!this.isComponent()) {
        this.router.navigate(['../view'], { relativeTo: this.route, queryParams: { id: res.id } });
      } else this.form().patchValue({ id: res.id, originalId: res.originalId });
      this.uS.notify(`Help content saved successfully`, 1);
      this.saved.emit(res);
    } catch (error) {
      this.uS.info(error, 0);
    }
    btn?.setLoader(false);
    this.setLoader(false);
  }
}

<loader [loading]="computedLoading()">
  @if (content(); as content) {
    @if (content.id) {
      <div class="row">
        @if (!hideReferencing()) {
          <div class="col-lg-3 col-xxl-2">
            <h5 class="">
              {{ 'References to this content' | appTranslate | async }}
            </h5>
            <loader [loading]="loadingReferences()" [height]="100">
              <div class="d-flex flex-column gap-2">
                <mat-accordion multi>
                  @for (item of references(); track item.id) {
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        {{ item.applicationCode }} - {{ item.moduleType }} - {{ item.refCat }} -
                        {{ item.refId }}
                      </mat-expansion-panel-header>

                      <div class="d-flex gap-2 rounded-10 p-2 flex-column reference-block">
                        <text-case-2 [value]="item.applicationCode" label="Application Code" />
                        <text-case-2 [value]="item.moduleType" label="Module Type" />
                        <text-case-2 [value]="item.refCat" label="Reference Category" />
                        <text-case-2 [value]="item.refId" label="Reference Value" />
                        <div class="edit-btn">
                          <app-btn
                            text="Edit"
                            icon="edit"
                            (mclick)="selectedReference.set(item); saveRefModal.open()"
                            [mini]="true" />
                          <app-btn
                            text="Delete"
                            icon="delete"
                            type="danger-outline"
                            (mclick)="deleteReference(item)"
                            [mini]="true" />
                        </div>
                      </div>
                    </mat-expansion-panel>
                  } @empty {
                    <div class="">
                      {{ 'There have been no references added' | appTranslate | async }}
                    </div>
                  }
                </mat-accordion>
                <div class="d-flex">
                  <app-btn text="Add Reference" [mini]="true" icon="add" (mclick)="saveRefModal.open()" />
                </div>
              </div>
            </loader>

            <modal-comp #saveRefModal header="Create Content Reference" [showFooter]="false" width="1000px">
              <ng-template modalBody>
                <save-content-reference
                  [contentID]="content.id"
                  [reference]="selectedReference()"
                  [baseReference]="baseReference()"
                  [isLandscape]="true"
                  [isLandscape]="true"
                  (saved)="saveRefModal.close(); refreshReferences()" />
              </ng-template>
            </modal-comp>
          </div>
        }

        <div class=" {{ content.id && !hideReferencing() ? 'col-lg-9 col-xxl-10' : 'col-12' }}">
          <cms-resource
            [allowEdit]="true"
            [allowSubRemapping]="allowSubRemapping()"
            [sideBarTemplate]="sideBarTempl"
            [content]="content"
            (selectedContentBlockList)="selectedContentBlockList.emit($event)"
            (openAddItem)="openAdd($event)"
            (removeItem)="removeItem($event)"
            (openSelectExistingItem)="openSelectBlock($event)"
            (openEdit)="openEdit($event)" />

          <ng-template #sideBarTempl>
            <div class="d-flex flex-column gap-2">
              <ng-container *ngTemplateOutlet="toolbarTemplate()" />

              <mat-slide-toggle [(ngModel)]="autoSave" [disabled]="!references()?.length">
                {{ 'Auto Save' | appTranslate | async }}
              </mat-slide-toggle>
              <app-btn
                group="save"
                (mclick)="save()"
                type="success"
                [loading]="autoSaving()"
                [disabled]="!content.subHelpGuideContent?.length" />
              <a
                mat-stroked-button
                target="_blank"
                routerLink="../view"
                class="rounded-10"
                [queryParams]="query()">
                <i class="fa fa-eye" aria-hidden="true"></i>
                {{ 'View' | appTranslate | async }}
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    } @else {
      <div class="container">
        @if (toolbarTemplate()) {
          <div class="row row-cols-lg-auto g-3 justify-content-between mb-3">
            <div>
              <h5 class="mb-0">
                {{ computedHeader() | appTranslate | async }}
              </h5>
            </div>
            <div class=" ">
              <div class="row row-cols-lg-auto justify-content-end g-3">
                <ng-container *ngTemplateOutlet="toolbarTemplate()" />
              </div>
            </div>
          </div>
        }
        <save-cms-content
          [baseData]="saveContentBaseData()"
          [isComponent]="true"
          [noConsecutiveCreation]="true"
          (saved)="createdContent($event)" />
      </div>
    }
  } @else {
    <div class="p-3">
      {{ 'Parent content could not found' | appTranslate | async }}
    </div>
  }
</loader>

<modal-comp #editContentModal header="Edit Content" [showFooter]="false" width="1000px">
  <ng-template modalBody>
    <save-cms-content
      [content]="selectedContent()"
      [noConsecutiveCreation]="true"
      [isComponent]="true"
      (saved)="connectResource($event); editContentModal.close()" />
  </ng-template>
</modal-comp>

<modal-comp #saveContentModal header="Create Content" [showFooter]="false" width="1000px">
  <ng-template modalBody>
    <save-cms-content
      [baseData]="saveContentBaseData()"
      [noConsecutiveCreation]="true"
      [isComponent]="true"
      (saved)="connectResource($event, { isAdd: true }); saveContentModal.close()" />
  </ng-template>
</modal-comp>

<modal-comp #selectContentModal header="Select Existing Content" [showFooter]="false" width="1000px">
  <cms-content-bank
    body
    [isModal]="true"
    (selectContent)="connectResource($event, { isAdd: true }); selectContentModal.close()" />
</modal-comp>

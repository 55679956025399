import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { CMSLayoutService } from '../cms-layout/cms-layout.service';
import { FindItemComponent, ISearchFormSchema, ITableCol } from 'ets-fe-ng-sdk';
import { CMSService } from '../cms.service';
import { SharedModule } from '../../../../Shared/shared.module';
import { IRowOption } from '../../../../Shared/models/index.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ICMSContent } from '../cms.model';
import { ModalComponent } from '../../../../Shared/components/modal/modal.component';
import { RenderCMSContentComponent } from './render-cms-content/render-cms-content.component';
import { debounceTime, lastValueFrom, Subject } from 'rxjs';

@Component({
  selector: 'cms-content-bank',
  standalone: true,
  imports: [SharedModule, FindItemComponent, RenderCMSContentComponent],
  templateUrl: './cms-content-bank.component.html',
  styleUrl: './cms-content-bank.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentBankComponent {
  readonly searchFormSchema = computed<ISearchFormSchema[]>(() => {
    const shared = this.cmsService.sharedFormSchema();
    return [
      { field: 'title', label: 'Title' },
      { field: 'text', label: 'Text' },
      { ...shared.type, field: 'type', label: 'Type' },
      { ...shared.content, field: 'originalId', label: 'Original Content' },
      {
        field: 'language',
        label: 'Language',
        type: 'select',
        options: this.cmsService.languages(),
        labelField: 'title',
        valueField: 'code',
      },
      { field: 'url', label: 'URL' },
      // { field: 'docId', label: 'File ID.' },
      { field: 'versionNo', label: 'Version Number' },
    ];
  });
  readonly searchColumns = computed<ITableCol<ICMSContent>[]>(() => [
    // {field:'originalId',label:'Original Id'},
    {
      f: 'title',
      t: 'Title',
      action: (row) => (this.isModal() ? this.selectContent.emit(row) : this.previewContent(row)),
    },
    { f: 'type', t: 'Type' },
    { f: 'language', t: 'Language' },
    { f: 'text', t: 'Text', formatter: this.cmsService.uS.trim(25) },
    { f: 'url', t: 'URL' },
    { f: 'docId', t: 'File Id.' },
    { f: 'versionNo', t: 'Version No.' },
  ]);
  readonly selectedContent = signal<ICMSContent>(null);
  readonly previewModalRef = viewChild<ModalComponent>('previewModal');

  readonly isModal = input<boolean>();
  readonly selectContent = output<ICMSContent>();

  readonly rowAction = computed<IRowOption<ICMSContent>[]>(() => {
    const isModal = this.isModal();
    return [
      {
        t: 'Edit',
        ignore: isModal,
        action: (row) =>
          this.router.navigate(['./edit'], { relativeTo: this.route, queryParams: { id: row.id } }),
      },
      {
        t: 'View',
        ignore: isModal,
        action: (row) =>
          this.router.navigate(['./view'], { relativeTo: this.route, queryParams: { id: row.id } }),
      },
      {
        t: 'Preview',
        action: (row) => this.previewContent(row),
      },
      {
        t: 'Create resource',
        ignore: isModal,
        action: (row) => this.openCreateResource(row),
      },
      {
        t: 'Delete',
        ignore: isModal,
        itemClass: 'text-danger',
        action: (row) => this.deleteContent(row),
      },
    ];
  });
  readonly fiCompRef = viewChild<FindItemComponent>(FindItemComponent);
  readonly sub = this.cmsService.uS.createSubscriptionManager();
  readonly refresh$ = new Subject();
  constructor(
    public layoutService: CMSLayoutService,
    public cmsService: CMSService,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  ngAfterViewInit(): void {
    this.sub.push(this.refresh$.pipe(debounceTime(2000)).subscribe(() => this.fiCompRef().refresh()));
  }

  previewContent(content: ICMSContent) {
    this.selectedContent.set(content);
    this.previewModalRef()?.open();
  }

  async deleteContent(content: ICMSContent) {
    try {
      const proceed = await this.cmsService.uS.confirm(`You are about to delete ${content.title}, proceed?`);
      if (!proceed) return;
      await lastValueFrom(this.cmsService.deleteHelpGuideContent(content.id, {}));
      this.refresh();
    } catch (error) {
      this.cmsService.uS.info(error, 0);
    }
  }

  refresh() {
    this.refresh$.next(true);
  }

  openCreateResource(content: ICMSContent) {
    this.router.navigate(['../create'], { relativeTo: this.route, queryParams: { id: content.id } });
  }
}

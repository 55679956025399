import { Component, computed, input, output, signal, viewChild } from '@angular/core';
import {
  FindItemComponent,
  IGetQuery,
  IRowOption,
  ISearchFormSchema,
  ITableCol,
  ModalComponent,
} from 'ets-fe-ng-sdk';
import { SharedModule } from '../../../../../Shared/shared.module';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, debounceTime, lastValueFrom } from 'rxjs';
import { ICMSContent } from '../../cms.model';
import { CMSService } from '../../cms.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cms-find-parent-contents',
  standalone: true,
  imports: [SharedModule, FindItemComponent],
  templateUrl: './cms-find-parent-contents.component.html',
  styleUrl: './cms-find-parent-contents.component.scss',
})
export default class CMSFindParentContentsComponent {
  readonly searchFormSchema = computed<ISearchFormSchema[]>(() => {
    const shared = this.cmsService.sharedFormSchema();
    return [
      { field: 'title', label: 'Title' },
      { field: 'text', label: 'Text' },
      { ...shared.type, field: 'type', label: 'Type' },
      { ...shared.content, field: 'originalId', label: 'Original Content' },
      {
        field: 'language',
        label: 'Language',
        type: 'select',
        options: this.cmsService.languages(),
        labelField: 'title',
        valueField: 'code',
      },
      { field: 'url', label: 'URL' },
      // { field: 'docId', label: 'File ID.' },
      { field: 'versionNo', label: 'Version Number' },
    ];
  });
  readonly searchColumns = computed<ITableCol<ICMSContent>[]>(() => [
    // {field:'originalId',label:'Original Id'},
    {
      f: 'title',
      t: 'Title',
      action: (row) => (this.isModal() ? this.selectContent.emit(row) : this.viewContent(row)),
    },
    { f: 'type', t: 'Type' },
    { f: 'language', t: 'Language' },
    { f: 'text', t: 'Text', formatter: this.cmsService.uS.trim(25) },
    { f: 'url', t: 'URL' },
    { f: 'docId', t: 'File Id.' },
    { f: 'versionNo', t: 'Version No.' },
  ]);
  readonly selectedContent = output<ICMSContent>();
  readonly previewModalRef = viewChild<ModalComponent>('previewModal');

  readonly isModal = input<boolean>();
  readonly isComponent = input<boolean>();
  readonly hideCreation = input<boolean>();
  readonly applicationCode = input<string>();
  readonly additionalRowOptions = input<IRowOption<ICMSContent>[]>();
  readonly selectContent = output<ICMSContent>();

  readonly showExport = computed(() => !this.isModal() && !this.isComponent());

  readonly rowAction = computed(() => {
    const isModal = this.isModal(),
      hideCreation = this.hideCreation();
    const ret: IRowOption<ICMSContent>[] = [
      {
        t: 'Edit',
        ignore: isModal || hideCreation,
        action: (row) =>
          this.router.navigate(['./edit'], { relativeTo: this.route, queryParams: { id: row.id } }),
      },
      {
        t: 'View',
        ignore: isModal,
        action: (row) => this.viewContent(row),
      },
    ];
    return (this.additionalRowOptions() || []).concat(ret);
  });
  readonly fiCompRef = viewChild<FindItemComponent>(FindItemComponent);

  readonly searchParentContent = computed(() => {
    this.applicationCode();
    return (query: IGetQuery) => this.cmsService.searchParentContent(query).pipe();
  });

  constructor(
    public cmsService: CMSService,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  viewContent(row: ICMSContent) {
    if (this.isComponent()) this.selectedContent.emit(row);
    else
      this.router.navigate(['./view'], {
        relativeTo: this.route,
        queryParams: { helpGuideContentId: row.id },
      });
  }
}

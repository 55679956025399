@if (service.allowEdit()) {
  <div class="d-flex center gap-2">
    <!-- <div class="">
    {{emitObject()|json}}
    </div> -->
    <app-btn [mini]="true" text="Edit" icon="edit" (mclick)="service.editItem$.next(emitObject())" />
    <app-btn
      [mini]="true"
      type="primary"
      text="Add"
      icon="add"
      (mclick)="service.addItem$.next(emitObject())" />
    <app-btn
      [mini]="true"
      text="Add Existing"
      icon="search"
      (mclick)="service.selectExistingItem$.next(emitObject())" />
    @if (!isRoot()) {
      <app-btn
        [mini]="true"
        type="danger"
        text="Remove"
        icon="delete"
        (mclick)="service.removeItem$.next(emitObject())" />
    }
    <!-- <mat-menu #addMenu="matMenu">
        @for (type of service.types(); track type.code) {
          <button
            mat-menu-item
            (click)="
              service.addItem$.next({
                newContent: { type: type.code },
                existingContent: content(),
                before: before(),
              })
            ">
            {{ type.title }}
          </button>
        }
      </mat-menu> -->
  </div>
}

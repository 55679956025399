import { computed, Injectable, signal } from '@angular/core';
import { debounceTime, ReplaySubject, Subject } from 'rxjs';
import { IAddCMSContentEvent, ICMSContent, ICMSResource } from '../../cms.model';
import { CMSService } from '../../cms.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class CMSResourceService {
  readonly openEdit = new Subject<ICMSResource>();
  readonly allowEdit = signal<boolean | null>(null);
  readonly allowSubRemapping = signal<boolean | null>(null);
  readonly types = computed(() => this.cmsContentService.types());
  readonly addItem$ = new Subject<IAddCMSContentEvent>();
  readonly editItem$ = new Subject<IAddCMSContentEvent>();
  readonly selectExistingItem$ = new Subject<IAddCMSContentEvent>();
  readonly removeItem$ = new Subject<IAddCMSContentEvent>();
  readonly activeContentBlock$ = new ReplaySubject<ICMSContent>();
  readonly activeContentBlock = toSignal(this.activeContentBlock$.pipe(debounceTime(600)));
  readonly resourceItemIDPrefix = `cms_item_`;

  protected readonly selectedContentBlock = signal<{
    [contentID: string]: { block: ICMSContent; date: number };
  }>({});
  readonly selectedContentBlockList = computed(() =>
    Object.values(this.selectedContentBlock())
      ?.sort2('date')
      .map((x) => x.block),
  );
  constructor(public cmsContentService: CMSService) {}

  drop(event: CdkDragDrop<ICMSContent[]>, list: ICMSContent[]) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  toggleContentBlockSelection(selected: boolean, block: ICMSContent) {
    this.selectedContentBlock.update((map) => {
      if (map[block.id]) {
        delete map[block.id];
        return { ...map };
      } else return { ...map, [block.id]: { block, date: Date.now() } };
    });
  }

  setActiveContentBlock(content: ICMSContent) {
    this.activeContentBlock$.next(content);
  }

  isScrolledIntoView(selector: string) {
    const rect = document.querySelector(selector)?.getBoundingClientRect();
    return rect ? rect.top >= 0 && rect.bottom <= window.innerHeight : false;
  }
}

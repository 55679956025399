<app-find-item
  [autoOrder]="false"
  [autoFormatSchema]="false"
  [showExport]="!isModal()"
  [startSectionTemplate]="startSectionTemplate"
  [formSchema]="searchFormSchema()"
  [rowOptions]="rowAction()"
  [showRefreshBtn]="true"
  [searchObservableFunc]="cmsService.searchHelpGuideContent"
  [displayedColumns]="searchColumns()" />
<ng-template #startSectionTemplate>
  @if (!isModal()) {
    <div class="">
      <app-btn text="Add Content" routerLink="./create" icon="add" />
    </div>
    <div class="">
      <app-btn text="Relationships" routerLink="../relationships" icon="search" />
    </div>
    <div class="">
      <app-btn text="References" routerLink="../references" icon="search" />
    </div>
  }
</ng-template>

<modal-comp #previewModal header="Preview Content" [showFooter]="false" width="1000px">
  <ng-template modalBody>
    <render-cms-content [content]="selectedContent()" />
    <!-- <hr> -->
    <div class="justify-content-end row row-cols-auto mt-3">
      <div>
        <app-btn
          icon="add"
          text="Create resource from content"
          [mini]="true"
          (mclick)="openCreateResource(selectedContent())" />
      </div>
    </div>
  </ng-template>
</modal-comp>


<!-- allowSubRemapping(): {{allowSubRemapping()}}
service.allowSubRemapping(): {{service.allowSubRemapping()}} -->
<div class="row mx-0" [id]="id">
  <div class="{{hideSideBar()?'col-12':'col-lg-9 col-xxl-10'}}  order-1 order-lg-0" >
    <cms-resource-item [isRoot]="true" [content]="content()" />
  </div>

  <div [hidden]="hideSideBar()" class="col-lg-3 col-xxl-2 order-lg-1 order-0">
    <div class="sidebar-container sticky-top">
      <div class=" d-flex flex-column gap-3">
        @if (sideBarTemplate(); as sideBarTemplate) {
          <ng-container *ngTemplateOutlet="sideBarTemplate"></ng-container>
        }
        @if (contentTree()?.length) {
          <div class="outline-cont">
            <h4 class="text-primary">
              {{ 'Outline' | appTranslate | async }}
            </h4>
            <div class="">
              <ng-template #titleTempl let-obj>
                <div class="d-flex align-items-center">
                  {{ obj.node.title }}
                  <span class="tree-item-type">{{ obj.node.type | titlecase }}</span>
                </div>
              </ng-template>
              <mat-list>
                @for (item of contentTree(); track item.id) {
                  <!-- @if (!item.meta?.hideTitle) { -->
                    <button
                      (click)="scrollIntoView(item)"
                      class="tree-item pointer w-100 mt-1 glass"
                      [ngClass]="{ active: item._inView }"
                      [matTooltip]="item.title"
                      [matTooltipShowDelay]="1000"
                      mat-button>
                      <div class="outlineItemTitle">{{ item.title }}</div>
                    </button>
                  <!-- } -->
                }
              </mat-list>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<loader [loading]="loading() && !content()._hidePreview" class="vh-10">
  @if (content(); as _content) {
    @if (_content._hidePreview) {
      <div class="d-flex gap-2 preview-text">
        {{ 'Preview is hidden' | appTranslate | async }}
        <div (click)="updateContent({ _hidePreview: false })" class="text-primary pointer">
          {{ 'Show Preview' | appTranslate | async }}
        </div>
      </div>
    } @else {
      <img [src]="image()" alt="{{ _content.title }} image" />
    }
    @if (_content.title) {
      <div class="image-caption" [innerHTML]="_content.title"></div>
    }
  }
</loader>

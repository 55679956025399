<app-find-item
  #fi
  [autoOrder]="false"
  [searchFunction]="searchParentContent()"
  [autoFormatSchema]="false"
  [showExport]="showExport()"
  [startSectionTemplate]="startSectionTemplate"
  [formSchema]="searchFormSchema()"
  [hideForm]="true"
  orderDirection="asc" orderField="title"
  [rowOptions]="rowAction()"
  [showRefreshBtn]="true"
  [displayedColumns]="searchColumns()" /> 
<ng-template #startSectionTemplate>
  @if (!hideCreation()) {
    <div class="">
      <app-btn text="Create Resource" routerLink="./create" icon="add" />
    </div>
  }
</ng-template>

import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { FormArray } from '@angular/forms';
import { CMSService } from '../../cms.service';
import { UtilityService } from '../../../../../Services/utility.service';
import { lastValueFrom, map, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ICMSContent, ICMSResource } from '../../cms.model';

@Injectable()
export class SaveCMSResourceService {
  readonly uS = inject(UtilityService);
  readonly service = inject(CMSService);
 

  readonly loading = signal(false);

  constructor() { 
  }

  protected buildChildren(parent: ICMSResource, children: ICMSResource[]) {
    if (!parent) return null;
    parent.subHelpGuideContent = parent.subHelpGuideContent || [];
    if (!children?.length) return parent;
    for (let index = 0; index < children.length; index++) {
      const item = children[index];
      if (item._parentID == parent._id)
        parent.subHelpGuideContent.push(this.buildChildren(item, children.slice(index + 1)));
    }
    return parent;
  }


}

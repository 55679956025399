import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CMSContentTypeComponent } from "../cms-content-type.base.component";

@Component({
  selector: 'render-cms-url',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './render-cms-url.component.html',
  styleUrl: './render-cms-url.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderCMSUrlComponent extends CMSContentTypeComponent { }

import { inject, Injectable } from '@angular/core';
import { UtilityService } from '../../../../../Services/utility.service';
import { AdminDocumentService } from 'projects/evolutics-admin-ui/src/app/shared/pages/documents/document.service';
import { ICMSContent } from '../../cms.model';
import { BtnComponent } from 'ets-fe-ng-sdk';
import { lastValueFrom } from 'rxjs';
import { EToastTag } from '@serene-dev/toast-notifications';

@Injectable({
  providedIn: 'root',
})
export class RenderCMSContentService {
  public readonly documentService = inject(AdminDocumentService);

  constructor(public uS: UtilityService) {}

  async donwloadFile(content: ICMSContent, btn?: BtnComponent) {
    btn?.setLoader(true);
    try {
      const toast = await this.uS.toast.add({
        header: 'File Download',
        tag: EToastTag.info,
        body: `Downloading ${content.title}`,
        loading: true,
      });
      // await (this.documentService.downloadFileBlob(content.docId));
      await lastValueFrom(
        this.documentService.downloadFileBlob(content.docId, {
          fileName: content.title + (content.meta?.fileExtension ? '.' + content.meta?.fileExtension : ''),
        }),
      );
      toast.update({
        loading: false,
        tag: EToastTag.success,
        header: 'File Downloaded',
        body: `Downloaded ${content.title}`,
      });
    } catch (error) {
      this.uS.info(error, 0);
    }
    btn?.setLoader(false);
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { SharedModule } from '../../../../../../Shared/shared.module';
import { IAddCMSContentEvent, ICMSResource } from '../../../cms.model';
import { CMSResourceService } from '../cms-resource.service';

@Component({
  selector: 'cms-resource-item-actions',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './cms-resource-item-actions.component.html',
  styleUrl: './cms-resource-item-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CMSResourceItemActionsComponent {
  readonly service = inject(CMSResourceService);

  readonly content = input<ICMSResource>();
  readonly isRoot = input<boolean>();

  protected readonly emitObject = computed<IAddCMSContentEvent>(() => ({
    previousContent: this.content(),
    // isChild: this.isRoot(),
  }));
}

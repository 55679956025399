<div class="row row-cols-lg-auto g-3 justify-content-between mb-3">
  <div>
      <h5 class="mb-0">
        {{ computedHeader() | appTranslate | async }}
      </h5>
  </div>
  <div class=" ">
    <div class="row row-cols-lg-auto justify-content-end g-3">
      <app-btn text="Map" type="success" icon="add" [disabled]="!_selectedContent()" (mclick)="mapSelectedContent(_selectedContent())" />
      @if (_selectedContent()) {
        <app-btn text="Back to Selection" icon="search" (mclick)="_selectedContent.set(null)" />
      }
      <ng-container *ngTemplateOutlet="toolbarTemplate()" />
    </div>
  </div>
</div>
@if (!_selectedContent()) {
  <cms-find-parent-contents
    [isComponent]="true"
    [applicationCode]="applicationCode()"
    [hideCreation]="true"
    [additionalRowOptions]="rowOptions()"
    (selectedContent)="_selectedContent.set($event)" />
} @else if (_selectedContent()) {
  <view-cms-resource [allowEdit]="false" [query]="query()" />
}

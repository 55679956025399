import { Injectable } from '@angular/core';
import { environment } from 'projects/evolutics-shared-lib/src/environments/environment';
import { ApiService } from '@Services/api.service';
import { map } from 'rxjs';
import { IGetQuery, ISearchResponse2 } from 'ets-fe-ng-sdk';
import { IAdminDocument } from './document.model';
import { UtilityService } from '@Services/utility.service';

@Injectable({
  providedIn: 'root',
})
export class AdminDocumentService {
  private baseUrl = environment.apiBaseUrl + '/admin/rest/';
  private coreBaseUrl = environment.apiBaseUrl + '/rest/';
  private wfBaseUrl = environment.jupiterWorkflowAPIURL + '/api/v1/workflow/';
  constructor(
    private apiService: ApiService,
    public uS: UtilityService,
  ) {}

  searchDocuments(
    query?: IGetQuery<{
      company: string;
      docKey: string;
      fileName: string;
      refCat: string;
      refNo: string;
    }>,
  ) {
    return this.apiService
      .get<ISearchResponse2<IAdminDocument>>(this.baseUrl + 'document', query)
      .pipe(map((res) => res.content));
  }

  getDocumentByID(id: string) {
    return this.apiService.get<IAdminDocument>(`${this.baseUrl}document/${id}`);
  }

  downloadFileArrayBuffer(docId: string, query?: { fileName?: string }) {
    return this.apiService.getFile(`${this.baseUrl}document/download/${docId}`).pipe(
      map((file) => {
        file;
        debugger;
        this.uS.downloader(file, query?.fileName || docId);
      }),
    );
  }

  getFileBlob(docId: string) {
    return this.apiService.get<Blob>(`${this.baseUrl}document/download/${docId}`, null, {
      options: { responseType: 'blob' },
    });
  }

  getFileArrayBuffer(docId: string) {
    return this.apiService.getFile<ArrayBuffer>(`${this.baseUrl}document/download/${docId}` );
  }

  downloadFileBlob(docId: string, query?: { fileName?: string }) {
    return this.apiService
      .get(`${this.baseUrl}document/download/${docId}`, null, { options: { responseType: 'blob' } })
      .pipe(
        map((file) => {
          this.uS.downloader(file, query?.fileName || docId);
        }),
      );
  }

  searchWFDocuments(query?: any) {
    return this.apiService
      .get<any>(this.wfBaseUrl + 'document/search', query)
      .pipe(map((res) => res.content));
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, model, viewChild } from '@angular/core';
import { CMSContentTypeComponent } from '../cms-content-type.base.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslatePipe } from '../../../../../../Shared/pipes/translate.pipe';
import { ICMSTable } from '../../../cms.model';

@Component({
  selector: 'render-cms-table',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TranslatePipe,
  ],
  templateUrl: './render-cms-table.component.html',
  styleUrl: './render-cms-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderCMSTableComponent extends CMSContentTypeComponent {
  readonly content = model.required<ICMSTable>();
  protected readonly query = model<string>();

  protected readonly columns = computed<string[]>(() => this.content()?.columns || []);
  protected readonly rows = computed(() => this.content()?.rows || []);
  protected readonly cellsCount = computed<number>(() => this.rows().length *this.columns().length);
  protected readonly showFilter = computed<boolean>(() => this.cellsCount() > 20);
  protected readonly showPager = computed<boolean>(() => this.cellsCount() > 30);
  protected readonly dataSource = computed(() => {
    const dataSource = new MatTableDataSource(this.rows());
    dataSource.paginator = this.paginator();
    dataSource.sort = this.sort();
    return dataSource;
  });
  protected readonly noDataMessage = computed<string>(() =>
    this.query() ? ` No data matching the filter "${this.query}"` : `There are no items`,
  );

  protected readonly paginator = viewChild(MatPaginator);
  protected readonly sort = viewChild(MatSort);

  d = effect(() => {
    console.log('rows', this.rows());
  });
  ngAfterViewInit() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource().filter = filterValue.trim().toLowerCase();

    if (this.dataSource().paginator) {
      this.dataSource().paginator.firstPage();
    }
  }
}

import { Component, computed, inject, input } from '@angular/core';
import { ICMSResource } from '../../../cms.model';
import { SharedModule } from '../../../../../../Shared/shared.module';
import { CMSResourceService } from '../cms-resource.service';
import { CMSResourceItemActionsComponent } from '../cms-resource-item-actions/cms-resource-item-actions.component';
import { RenderCMSContentComponent } from '../../../cms-content-bank/render-cms-content/render-cms-content.component';
import { CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'cms-resource-item',
  standalone: true,
  imports: [
    SharedModule,
    RenderCMSContentComponent,
    MatCheckboxModule,
    CMSResourceItemActionsComponent,
    CdkDropList,
    CdkDrag,
  ],
  templateUrl: './cms-resource-item.component.html',
  styleUrl: './cms-resource-item.component.scss',
})
export class CMSResourceItemComponent {
  readonly service = inject(CMSResourceService);
  readonly content = input<ICMSResource>();
  readonly isRoot = input<boolean>();

  readonly id = computed(() => this.service.resourceItemIDPrefix + this.content().id);
}
